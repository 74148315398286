import {memo, useEffect, useRef, useState} from "react";

const DURATION = 600;
const NUMS = new Array(100).fill(100).map((_, i) => i);

function easeInOutCubic(elapsed, initialValue, amountOfChange, duration) {
	if ((elapsed /= duration / 2) < 1) {
		return (amountOfChange / 2) * elapsed * elapsed * elapsed + initialValue;
	}
	return (
		(amountOfChange / 2) * ((elapsed -= 2) * elapsed * elapsed + 2) +
		initialValue
	);
}

export function Odometer(props) {
	const {value = 0, maxValue = 0, itemSize = 40} = props;

	return <div className="odometer">
		<RollingNumber
			direction={1}
			target={value}
			itemSize={itemSize}
		/>
	</div>;
}

export const RollingNumber = memo((props) => {
	const {direction, target, itemSize} = props;
	const [currentPosition, setCurrentPosition] = useState(getNewPosition());
	const targetRef = useRef(null);

	function getStartPosition() {
		return target * itemSize * -1 + ((itemSize / 2) * -1) - itemSize * -2;
	}

	useEffect(() => {
		targetRef.current = target;
		const startTime = new Date();
		let startPosition = roundNumber(getStartPosition(), 3);
		if (direction > 0) {
			startPosition = startPosition - itemSize * 2;
		}
		const offset = itemSize * direction;

		function tick() {
			// console.log("animation", target, startPosition, offset, direction)
			const elapsed = new Date() - startTime;
			const position = easeInOutCubic(elapsed, startPosition, offset, DURATION);
			setCurrentPosition(roundNumber(position, 3));

			if (elapsed < DURATION && targetRef.current == target) {
				requestAnimationFrame(tick);
			}
		}

		tick();
	}, [direction, target]);

	function renderNumbers() {
		return NUMS.map((n, i) => <span key={i} className={target === n ? 'active' : ''}>{n}</span>);
	}

	function getNewPosition() {
		return target * itemSize * -1 + ((itemSize / 2) * -1);
	}

	return (
		<div className="rolling-number-window">
			<div className="rolling-number-placeholder"></div>
			<div
				className="rolling-number"
				style={{
					transform: `translateY(${currentPosition}px)`
				}}
			>
				{renderNumbers()}
			</div>
		</div>
	);
})

function roundNumber(num, digits) {
	const decimal = Math.pow(10, digits);
	return Math.round(num * decimal) / decimal;
}

