import {CountdownNumber} from "./CountdownNumber";
import React from "react";
import Countdown from "react-countdown";

const OdometerItem = (props) => {
	return <div className="odometer-item">
		<CountdownNumber value={props.value} itemSize={props.itemSize}/>
		<div className="odometer-item__text">{props.text}</div>
	</div>
}

const CountdownRenderer = (props) => {
	const {days, hours, minutes, seconds, size = 'x5-countdown--desktop', itemSize = 70} = props;
	return <div className={'x5-countdown ' + size}>
		<img className="x5-countdown__img" src="img/sand-clock.svg"/>
		<div className="x5-countdown__header">Смотри розыгрыш через...*</div>
		<div className="x5-countdown__body">
			<OdometerItem value={days} text={'дни'} itemSize={itemSize} size={size} maxValue={31}></OdometerItem>
			<OdometerItem value={hours} text={'часы'} itemSize={itemSize} size={size} maxValue={24}></OdometerItem>
			<OdometerItem value={minutes} text={'минуты'} itemSize={itemSize} size={size} maxValue={60}></OdometerItem>
			<OdometerItem value={seconds} text={'секунды'} itemSize={itemSize} size={size} maxValue={60}></OdometerItem>
		</div>
		<div className="x5-countdown__footer">*Дата розыгрыша 15.02.2025 г. в 00:00 (по мск)</div>
	</div>
}

export const X5Countdown = ({size, itemSize}) => {
	return <>
		<Countdown
			date={new Date('2025-02-15 00:00:00')}
			now={() => new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"}))}
			intervalDelay={0}
			precision={3}
			renderer={props => <CountdownRenderer size={size} itemSize={itemSize} {...props}></CountdownRenderer>}
		/>
	</>
}