import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

export const FaqList = () => {
  return (
    <>
    <img className='faqImg' src="img/faqHeader.png" id="faq" />
      <div className="FaqList" style={{marginTop: '-100px'}}>
        <div className='x5-accordion'>
          <section class="accordion">
            <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb2" />
              <label for="cb2" class="tab__label">Как принять участие в конкурсе?</label>
              <div class="tab__content">
                <p>Для того, чтобы принять участие в конкурсе, необходимо быть действующим сотрудником Х5, отправить до 8 января 2025 г. включительно в Мечтаграм свою мечту, пройти модерацию и ждать розыгрыш.
                </p>
                <p>
                15 февраля 2025 г. случайным образом будут выбраны 80 желаний, авторам которых Х5 поможет реализовать описанную ими мечту. 
                </p>
                <p>
                Также мы определим имена 200 сотрудников, кому вручим специальные новогодние подарки. 
                </p>
                <p>
                А ещё 5 сотрудников выберет благотворительный фонд «Выручаем» и исполнит их мечты.                </p>
              </div>
            </div>
          </section>
          <section class="accordion">
            <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb3" />
              <label for="cb3" class="tab__label">Какие условия конкурса?</label>
              <div class="tab__content">
                <p>С условиями конкурса можно ознакомиться <a href='rules/Мотивационная_акция_Мечтаграм_20241208_2.docx' target="_blank">по ссылке</a> 
                </p>
              </div>
            </div>
          </section>
          <section class="accordion">
            <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb4" />
              <label for="cb4" class="tab__label">Какие критерии исполнения мечты?</label>
              <div class="tab__content">
                <p>Мечта должна быть выполнимой!</p>
                <p>Также в денежном эквиваленте она не должна превышать 30.000 руб. (после вычета НДФЛ и после стоимости доставки подарка победителю) и 
                соответствовать теме Правил акции. 
                </p>
                <p>И, конечно, мечта не должна иметь негативного или отталкивающего содержания.</p>
                <p>Подробнее читай в <a href='rules/Мотивационная_акция_Мечтаграм_20241208_2.docx' target="_blank">правилах</a></p>
              </div>
            </div>
          </section>
          <section class="accordion">
            <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb5" />
              <label for="cb5" class="tab__label">Что можно выиграть?</label>
              <div class="tab__content">
                <p>Реализацию мечты. Мечта может быть выбрана рандомайзером или благотворительным фондом «Выручаем».
                </p>
              </div>
            </div>
          </section>
          <section class="accordion">
            <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb6" />
              <label for="cb6" class="tab__label">Сколько желаний можно отправлять?</label>
              <div class="tab__content">
                <p>Ты можешь отправить неограниченное количество желаний. Но в рандомайзер попадёт только первая отправленная тобой мечта, которая полностью соответствует условиям конкурса. Таким образом, от каждого участника в розыгрыше может участвовать только одна мечта.
                </p>
              </div>
            </div>
          </section>
          <section class="accordion">
            <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb7" />
              <label for="cb7" class="tab__label">Как узнать, что я в числе победителей?</label>
              <div class="tab__content">
                <p>15.02.2025 г. смотри трансляцию на площадке Мечтаграм, во время которой случайным образом будут определены имена победителей и объявлены 5 участников, выбранных благотворительным фондом «Выручаем».
                </p>
              </div>
            </div>
          </section>
          <section class="accordion">
            <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb8" />
              <label for="cb8" class="tab__label">Как помочь исполнить мечту нуждающихся, сделав пожертвование?</label>
              <div class="tab__content">
                <p>Нажимай на кнопку «Исполнить мечту» на площадке Мечтаграм, переходи на сайт благотворительного фонда «Выручаем» и делай пожертвование. Собранные средства пойдут на продуктовые наборы для нуждающихся. А ты получишь бейдж «Исполнитель желаний» на внутреннем портале и шанс стать одним из 5-ти победителей, которых выберет благотворительный фонд «Выручаем», чтобы исполнить и их мечты. Победители будут объявлены 15.02.2025 г. (о точном времени мы сообщим дополнительно) на площадке  Мечтаграм.
                </p>
              </div>
            </div>
          </section>
          <section class="accordion">
            <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb9" />
              <label for="cb9" class="tab__label">Когда мне будет начислен бейдж «Исполнитель желаний» на корпоративном портале?</label>
              <div class="tab__content">
                <p>Сроки начисления бейджа в личном кабинете корпоративного портала – 1 (один) раз в неделю:
                </p>
                <p>
                - «16» декабря 2024 г. до 23 часов 59 мин 59 сек
                </p>
                <p>
                - «23» декабря 2024 г. до 23 часов 59 мин 59 сек
                </p>
                <p>
                - «30» декабря 2024 г. до 23 часов 59 мин 59 сек
                </p>
                <p>
                - «06» января 2025 г. до 23 часов 59 мин 59 сек
                </p>
                <p>
                - «10» января 2025 г. до 23 часов 59 мин 59 сек
                </p>
              </div>
            </div>
          </section>
          <section class="accordion">
            <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb10" />
              <label for="cb10" class="tab__label">Если у меня остались вопросы, как я могу их задать?</label>
              <div class="tab__content">
                <p>Задай вопрос на адрес электронной почты mechtagram@x5.ru, и мы ответим тебе в течение 72 часов.
                </p>
              </div>
            </div>
          </section>
          <section class="accordion">
            <div class="tab">
              <input type="checkbox" name="accordion-1" id="cb11" />
              <label for="cb11" class="tab__label">Можно ли в рамках акции перечислить помощь благотворительному фонду «Выручаем» без указания корпоративной электронной почты?</label>
              <div class="tab__content">
                <p>Просьба указывать корпоративную почту, чтобы мы могли начислить бейдж «Исполнитель желаний».
                </p>
              </div>
            </div>
          </section>
        </div>
        {/* <ControlledAccordions></ControlledAccordions> */}
      </div>
    </>
  );
};

const ExpandMoreIcon = () => {
  return <>+</>
}

const font = {
  fontSize: '24px',
  fontFamily: 'X5 Sans',
  fontWeight: 500
}

export function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='x5-accordion'>
      {Array(10).fill(0).map((_, i) => {
        return <Accordion expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ textAlign: 'center', ...font }}>
              Как исполнить мечту?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>Для того, чтобы принять участие в конкурсе, необходимо<br />
                быть действующим сотрудником Х5 и отправить до 8 января <br />
                2025 г. включительно в Мечтаграм свою мечту. <br />
                15 февраля 2025 г. случайным образом будут выбраны 80<br />
                мечт, авторам которых Х5 поможет реализовать описанную <br />
                ими мечту.
              </p>
              <p>
                Также мы определим имена 200 сотрудников, кому вручим <br />
                специальные новогодние подарки и имена ещё 5-ти <br />
                сотрудников, чьи мечты выберет и исполнит вместе с Х5<br />
                благотворительный фонд «Выручаем»
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      })}
    </div>
  );
}


