import React from 'react';

export const ConfirmedWishModal = ({ id, close }) => {
    return (
        <div className="confirm-wish-modal">
            <div className="confirm-wish-modal__title">Ура! Твоя мечта проходит модерацию</div>
            <div className="confirm-wish-modal__text">В течение 3х дней твоя мечта появится в Мечтаграме под номером: #{id}</div>
            <div className="confirm-wish-modal__link">
            <a href='rules/Мотивационная_акция_Мечтаграм_20241208_2.docx' target="_blank">Читать условия конкурса</a>   
                </div>
            <div className="confirm-wish-modal__buttons">
                <button className="x5-button x5-button--yes" onClick={close}>
                    Огонь!
                </button>
            </div>
        </div>
    );
};
