import React, {useEffect, useRef, useState} from 'react';
import useAxios from 'axios-hooks';
import {WishRow} from './wish/WishRow';
import ReactPaginate from 'react-paginate';
import {HOST, AUHT_TOKEN} from '../env';
import {useNavigate} from "react-router-dom";

const TreeLeftBlur = () => <img src="img/newwish/tree-left-blur.svg"/>;
const TreeLeft = () => <img src="img/newwish/tree-left.svg"/>;
const TreeRightBlue = () => <img src="img/newwish/tree-rigth-blur.svg"/>;
const Light1 = () => <img src="img/newwish/light.svg"/>;
const GiftSvg = () => <img src="img/wishtable/gift.svg"/>;
const EllipseSvg = () => <img src="img/wishtable/ellipse.svg"/>;

export const WishTable = () => {
	const [currentItems, setCurrentItems] = useState(null);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const wishRef = useRef(null);
	const itemsPerPage = 12;
	const [{data, loading, error}] = useAxios({
		url: HOST + '/items/wishes',
		method: 'GET',
		params: {
			limit: 10000,
			fields: ['wish', 'id'],
			sort: ['id'],
			page: 1,
			filter: {
				_or: [
					{wish_state: {_eq: 'accept'}},
					{force_show: {_eq: true}}
				]
			},
		},
		headers: {
			Authorization: AUHT_TOKEN,
		},
	});

	let res = [];
	if (!loading && !error) {
		res = data.data;
	}

	const executeScroll = () => wishRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})

	useEffect(() => {
		if (wishRef && wishRef.current) {
			executeScroll();
		}
	}, [itemOffset]);

	useEffect(() => {
		const endOffset = itemOffset + itemsPerPage;
		setCurrentItems(res.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(res.length / itemsPerPage));
	}, [itemOffset, itemsPerPage, res]);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % res.length;
		setItemOffset(newOffset);
	};

	const searchWishes = (searchValue) => {
		if (searchValue !== '') {
			const filteredData = data.data.filter((item) => {
				return String(item.id).indexOf(searchValue) > -1 || item.wish.indexOf(searchValue) > -1
			});
			setCurrentItems(filteredData);
		} else {
			const endOffset = itemOffset + itemsPerPage;
			setCurrentItems(res.slice(itemOffset, endOffset));
		}
	};

	if (error) return <p>Error: {error.message}</p>;
	return (
		<div className="wish-table-page" ref={wishRef}>
			<div className="wish-table">
				<div className="wish-table__title-container">
					<div className="wish-table__title--gift">
						<GiftSvg></GiftSvg>
					</div>
					<div className="wish-table__title--back">
						<EllipseSvg></EllipseSvg>
					</div>
					<div className="wish-table__title wish-table__title--top">Мечтаграм</div>
				</div>
				<div className="wish-table__input">
					<input
						className="wish-table__text-input"
						placeholder="Введи номер мечты"
						onChange={(e) => searchWishes(e.target.value)}
						type="text"
					></input>
				</div>
				{
					loading
						? <div className="wish-table-page--loading"></div>
						: <div className="wish-row">
							<WishRow currentItems={currentItems} key={currentItems.id}/>
						</div>
				}
				<ReactPaginate
					nextLabel=">"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					pageCount={pageCount}
					previousLabel="<"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
					renderOnZeroPageCount={null}
				/>
			</div>

			<div className="wish-table-page__tree-right-blur-light"></div>
			<div className="wish-table-page__tree-right-blur">
				<TreeRightBlue></TreeRightBlue>
			</div>
			<div className="wish-table-page__light1">
				<Light1></Light1>
			</div>
		</div>
	);
};
