import React from "react";
import { BoxGroup1, BoxGroupWithText, BoxSingleText } from "./MechtagramBox";

export const MeshatagramIs2 = () => {
	return <BoxGroupWithText margin={"0 0 5rem 0"}></BoxGroupWithText>;
};

export const MeshatagramIs2Img = () => {
	return (
		<div>
			<img src="img/aboutPresents.png" />
		</div>
	);
};

export const DoWish = () => {
	return (
		<>
			<div style={{ marginBottom: "10rem" }}>
				<BoxSingleText title={"Мечтаграм это ..."}></BoxSingleText>
			</div>
			<div className="doWishFrames">
				<div className="doWish">
					<svg
						className="star1"
						width="148"
						height="149"
						viewBox="0 0 148 149"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g filter="url(#filter0_f_197_1210)">
							<path
								d="M86.3345 16.3393C90.401 13.3454 96.2118 15.6352 97.1101 20.5855L102.426 49.8802C102.742 51.6219 103.729 53.1716 105.178 54.1989L129.542 71.4783C133.659 74.3982 133.262 80.6039 128.805 82.9861L102.434 97.0835C100.866 97.9216 99.6889 99.3355 99.1534 101.024L90.1464 129.42C88.6243 134.219 82.5681 135.764 78.9157 132.286L57.3015 111.704C56.0164 110.481 54.3014 109.805 52.5219 109.821L22.5914 110.091C17.5336 110.137 14.1877 104.887 16.3867 100.355L29.3999 73.5371C30.1736 71.9426 30.2908 70.1111 29.7265 68.4327L20.2355 40.2035C18.6317 35.4333 22.62 30.6428 27.6315 31.32L57.2882 35.3277C59.0515 35.566 60.839 35.1099 62.2697 34.0565L86.3345 16.3393Z"
								fill="#5FAF2D"
							/>
						</g>
						<defs>
							<filter
								id="filter0_f_197_1210"
								x="0.879735"
								y="0.180516"
								width="146.343"
								height="148.812"
								filterUnits="userSpaceOnUse"
								color-interpolation-filters="sRGB"
							>
								<feFlood
									flood-opacity="0"
									result="BackgroundImageFix"
								/>
								<feBlend
									mode="normal"
									in="SourceGraphic"
									in2="BackgroundImageFix"
									result="shape"
								/>
								<feGaussianBlur
									stdDeviation="7.40974"
									result="effect1_foregroundBlur_197_1210"
								/>
							</filter>
						</defs>
					</svg>
					<svg
						className="star2"
						width="86"
						height="87"
						viewBox="0 0 86 87"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g filter="url(#filter0_f_197_1211)">
							<path
								d="M31.3825 17.8394C31.7433 15.462 34.4708 14.2768 36.4404 15.6416L48.0962 23.718C48.7892 24.1982 49.6436 24.3876 50.4773 24.2459L64.4995 21.8627C66.8689 21.46 68.8253 23.6836 68.1272 25.9863L63.9964 39.6127C63.7509 40.4229 63.8307 41.2954 64.219 42.0443L70.7501 54.6403C71.8538 56.7688 70.3353 59.3283 67.9343 59.3866L53.7255 59.7318C52.8808 59.7523 52.0757 60.1021 51.482 60.7067L41.4963 70.8746C39.8089 72.5928 36.9141 71.951 36.1282 69.6844L31.4775 56.2713C31.201 55.4739 30.6236 54.8176 29.8684 54.4423L17.1657 48.1304C15.0192 47.0638 14.7486 44.1077 16.664 42.6485L27.9984 34.0136C28.6723 33.5002 29.1205 32.7448 29.2474 31.9083L31.3825 17.8394Z"
								fill="#5FAF2D"
							/>
						</g>
						<defs>
							<filter
								id="filter0_f_197_1211"
								x="0.558446"
								y="0.244969"
								width="85.3777"
								height="86.4263"
								filterUnits="userSpaceOnUse"
								color-interpolation-filters="sRGB"
							>
								<feFlood
									flood-opacity="0"
									result="BackgroundImageFix"
								/>
								<feBlend
									mode="normal"
									in="SourceGraphic"
									in2="BackgroundImageFix"
									result="shape"
								/>
								<feGaussianBlur
									stdDeviation="7.40974"
									result="effect1_foregroundBlur_197_1211"
								/>
							</filter>
						</defs>
					</svg>
					<svg
						className="star3"
						width="66"
						height="65"
						viewBox="0 0 66 65"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g filter="url(#filter0_f_197_1212)">
							<path
								d="M32.9252 16.166C33.8437 15.0167 35.6515 15.2231 36.2776 16.5488L39.9829 24.3939C40.2032 24.8604 40.598 25.2221 41.0831 25.4021L49.242 28.4289C50.6208 28.9404 50.9771 30.7171 49.9028 31.7237L43.5454 37.6802C43.1675 38.0344 42.9429 38.5211 42.9195 39.0367L42.5268 47.7089C42.4604 49.1744 40.8728 50.066 39.5827 49.3624L31.9484 45.1986C31.4945 44.9511 30.9609 44.8901 30.4614 45.0288L22.0597 47.3617C20.64 47.7559 19.3025 46.5302 19.5794 45.0888L21.2185 36.5589C21.316 36.0518 21.2108 35.5274 20.9254 35.0975L16.1256 27.8671C15.3145 26.6453 16.0755 24.9961 17.5368 24.8089L26.1841 23.7009C26.6982 23.6351 27.1668 23.3719 27.49 22.9675L32.9252 16.166Z"
								fill="#5FAF2D"
							/>
						</g>
						<defs>
							<filter
								id="filter0_f_197_1212"
								x="0.975438"
								y="0.596776"
								width="64.3753"
								height="63.8301"
								filterUnits="userSpaceOnUse"
								color-interpolation-filters="sRGB"
							>
								<feFlood
									flood-opacity="0"
									result="BackgroundImageFix"
								/>
								<feBlend
									mode="normal"
									in="SourceGraphic"
									in2="BackgroundImageFix"
									result="shape"
								/>
								<feGaussianBlur
									stdDeviation="7.40974"
									result="effect1_foregroundBlur_197_1212"
								/>
							</filter>
						</defs>
					</svg>
					<p className="descHeader">
						Возможность в Новый
						год осуществить
						свою мечту
					</p>
					<p className="desc">
						Eсли твоя мечта станет одной из 80 выбранных с помощью
						рандомайзера, она будет исполнена по мановению 
						волшебной палочки! Ещё 200 счастливчиков получат 
						специальные новогодние подарки, а также 5 
						дополнительных заветных желаний исполнит
						благотворительный фонд «Выручаем»!
					</p>
					{/*<a href="/#shareDream" className="descBtn">*/}
					{/*	<div className="descBtnText">Поделиться мечтой</div>*/}
					{/*	<svg*/}
					{/*		width="24"*/}
					{/*		height="25"*/}
					{/*		viewBox="0 0 24 25"*/}
					{/*		fill="none"*/}
					{/*		xmlns="http://www.w3.org/2000/svg"*/}
					{/*	>*/}
					{/*		<circle*/}
					{/*			cx="12"*/}
					{/*			cy="12.7573"*/}
					{/*			r="12"*/}
					{/*			fill="#5FAF2D"*/}
					{/*		/>*/}
					{/*		<path*/}
					{/*			d="M8.25391 17.9226L10.0004 19.6691L16.9864 12.6831L15.2399 10.9366L8.25391 17.9226Z"*/}
					{/*			fill="white"*/}
					{/*		/>*/}
					{/*		<path*/}
					{/*			d="M15.2397 14.4296L16.9862 12.6831L10.0003 5.6971L8.25377 7.44359L15.2397 14.4296Z"*/}
					{/*			fill="white"*/}
					{/*		/>*/}
					{/*	</svg>*/}
					{/*</a>*/}
				</div>
				<div className="doWish">
					<svg
						className="star1"
						width="144"
						height="143"
						viewBox="0 0 144 143"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g filter="url(#filter0_f_197_1207)">
							<path
								d="M82.1748 19.4089C88.6112 12.7269 99.8854 15.5009 102.468 24.4022L128.088 112.69C130.671 121.591 122.625 129.943 113.606 127.724L24.1475 105.713C15.1281 103.493 11.8993 92.3668 18.3357 85.6847L82.1748 19.4089Z"
								fill="#5FAF2D"
							/>
						</g>
						<defs>
							<filter
								id="filter0_f_197_1207"
								x="0.141942"
								y="0.885594"
								width="143.255"
								height="142.02"
								filterUnits="userSpaceOnUse"
								color-interpolation-filters="sRGB"
							>
								<feFlood
									flood-opacity="0"
									result="BackgroundImageFix"
								/>
								<feBlend
									mode="normal"
									in="SourceGraphic"
									in2="BackgroundImageFix"
									result="shape"
								/>
								<feGaussianBlur
									stdDeviation="7.40974"
									result="effect1_foregroundBlur_197_1207"
								/>
							</filter>
						</defs>
					</svg>
					<svg
						className="star2"
						width="82"
						height="82"
						viewBox="0 0 82 82"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g filter="url(#filter0_f_197_1208)">
							<path
								d="M25.8312 19.6802C26.8334 15.608 31.8668 14.1523 34.8913 17.06L64.8897 45.9001C67.9142 48.8078 66.6614 53.8981 62.6347 55.0626L22.6958 66.6129C18.6691 67.7774 14.8885 64.1428 15.8907 60.0706L25.8312 19.6802Z"
								fill="#5FAF2D"
							/>
						</g>
						<defs>
							<filter
								id="filter0_f_197_1208"
								x="0.908055"
								y="0.715184"
								width="80.4788"
								height="80.9376"
								filterUnits="userSpaceOnUse"
								color-interpolation-filters="sRGB"
							>
								<feFlood
									flood-opacity="0"
									result="BackgroundImageFix"
								/>
								<feBlend
									mode="normal"
									in="SourceGraphic"
									in2="BackgroundImageFix"
									result="shape"
								/>
								<feGaussianBlur
									stdDeviation="7.40974"
									result="effect1_foregroundBlur_197_1208"
								/>
							</filter>
						</defs>
					</svg>
					<svg
						className="star3"
						width="57"
						height="59"
						viewBox="0 0 57 59"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g filter="url(#filter0_f_197_1209)">
							<path
								d="M36.5174 15.3864C38.4923 14.1916 41.0178 15.584 41.0632 17.8927L41.514 40.7916C41.5595 43.1003 39.0908 44.5938 37.0705 43.4799L17.0318 32.4316C15.0114 31.3177 14.9546 28.4318 16.9295 27.237L36.5174 15.3864Z"
								fill="#5FAF2D"
							/>
						</g>
						<defs>
							<filter
								id="filter0_f_197_1209"
								x="0.66245"
								y="0.129735"
								width="55.6717"
								height="58.5464"
								filterUnits="userSpaceOnUse"
								color-interpolation-filters="sRGB"
							>
								<feFlood
									flood-opacity="0"
									result="BackgroundImageFix"
								/>
								<feBlend
									mode="normal"
									in="SourceGraphic"
									in2="BackgroundImageFix"
									result="shape"
								/>
								<feGaussianBlur
									stdDeviation="7.40974"
									result="effect1_foregroundBlur_197_1209"
								/>
							</filter>
						</defs>
					</svg>
					<p className="descHeader">
						Возможность творить
						волшебство самим,
						<br />
						и исполнить чьё-то
						желание
					</p>
					<p className="desc2">

						Порой самые искренние желания бывают совсем просты и
						скромны — как, например, мечта о тёплом обеде.
						Собранные здесь пожертвования благотворительным фондом «Выручаем» пойдут на продуктовые наборы
						для нуждающихся. А тот, кто успел внести свой вклад,
						получит бейдж «Исполнитель желаний» на
						внутреннем портале.
					</p>
					{/*<a href="/#dowish" className="descBtn">*/}
					{/*	<div className="descBtnText">Исполнить мечту</div>*/}
					{/*	<svg*/}
					{/*		width="24"*/}
					{/*		height="25"*/}
					{/*		viewBox="0 0 24 25"*/}
					{/*		fill="none"*/}
					{/*		xmlns="http://www.w3.org/2000/svg"*/}
					{/*	>*/}
					{/*		<circle*/}
					{/*			cx="12"*/}
					{/*			cy="12.7573"*/}
					{/*			r="12"*/}
					{/*			fill="#5FAF2D"*/}
					{/*		/>*/}
					{/*		<path*/}
					{/*			d="M8.25391 17.9226L10.0004 19.6691L16.9864 12.6831L15.2399 10.9366L8.25391 17.9226Z"*/}
					{/*			fill="white"*/}
					{/*		/>*/}
					{/*		<path*/}
					{/*			d="M15.2397 14.4296L16.9862 12.6831L10.0003 5.6971L8.25377 7.44359L15.2397 14.4296Z"*/}
					{/*			fill="white"*/}
					{/*		/>*/}
					{/*	</svg>*/}
					{/*</a>*/}
				</div>
			</div>
		</>
	);
};

export const Features = (props) => {
	return (
		<>
			<div className="description123"></div>

			<div className="container">
				<div className="col"></div>
				<div className="description"></div>
			</div>
		</>
	);
};
export const Presents = (props) => {
	return <BoxGroup1></BoxGroup1>;
};
