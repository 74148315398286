import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {FaqList} from "../../Team";
import {ShareDream2, ShareRules} from "../../about";
import {CompleteDreamDesc} from "../../completeDream";
import {Contact} from "../../contact";
import {DoWish, MeshatagramIs2, Presents} from "../../features";
import {Header} from "../../header";
import {WishList} from "../../wishList/WishList";
import {useModal} from "../../../hooks/useModal";
import {MainModal} from "../../modals/MainModal";
import useLocalStorage from "../../../hooks/useLocalStorage";

export const LandingPage = () => {
	const LocalStorage = localStorage.getItem("isRegistered");
	const navigate = useNavigate();

	const hashUrl = document.location.hash;

	useEffect( () => {
		const onPageLoad = () => {
			document.location.hash = hashUrl;
		};
		if (document.readyState === 'complete') {
			onPageLoad();
		}
	}, [])

	useEffect(() => {
		if (!LocalStorage) {
			navigate("/registration");
		}

		const srollContainer = document.getElementsByClassName(
			"x5-scroll-container"
		)[0];

		srollContainer.scrollTo(0, 0);
	}, []);

	return (
		<div className="section-container">
			<div className="section first-section" id="landingPage">
				<img src="/img/KV.png" style={{zIndex: "0"}}/>
				<Header/>
			</div>
			<div className="section">
				<DoWish/>
			</div>
			<div className="section">
				<WishList></WishList>
			</div>
			<div className="section" style={{position: "relative"}}>
				<img
					className="snowImg"
					src="/img/snowImg.png"
					style={{
						zIndex: "0",
						position: "absolute",
						bottom: "-150px",
					}}
				/>
				<FaqList/>
			</div>
			<div className="section section--align-end">
				<Contact/>
			</div>
		</div>
	);
};
